import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectAppSlice } from '../../../app/slice';
import AiraRecommendation from '../../../common/components/AiraRecommendation';
import { changeObjAlert, showAlert } from '../../../common/components/Alert/slice';
import Button from '../../../common/components/Button';
import ExtensionRecommendation from '../../../common/components/ExtensionRecommendation';
import Header from '../../../common/components/Header';
import { selectMenuSlice } from '../../../common/components/SideMenu/slice';
import { updateTabTitle } from '../../../utils/updateTabTitle';
import InfluencerList from './components/InfluencerList';
import SearchInfluencer from './components/Search';
import { selectHomeSlice } from './slice';

import './index.scss';
import WorkspaceRecommendation from '../../../common/components/WorkspaceRecommendation';

const Home = ({ listId }) => {
  const [selectDot, updateSelectDot] = useState(1);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const slice = useSelector(selectHomeSlice);
  const appSlice = useSelector(selectAppSlice);
  const menuSlice = useSelector(selectMenuSlice);

  updateTabTitle('AirSearch');

  const user = appSlice.user;
  const collapsed = menuSlice.collapsed;
  const percentageQuota = slice.percentageQuota;

  let intervalId;

  useEffect(() => {
    intervalId = setInterval(() => {
      updateSelectDot((selectDot) => (selectDot === 1 ? 2 : 1));
    }, 7000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const slider = document.querySelector('.recommendations .slider');

    if (slider) slider.style.transform = `translateX(-${(selectDot - 1) * 75}%)`;
  }, [selectDot]);

  const tryAgainOpenWpp = () => {
    dispatch(
      changeObjAlert({
        show: true,
        type: 'success',
        title: t('Sucesso!'),
        text: t('Você foi redirecionado para o WhatsApp.'),
        cancelText: t('Fechar'),
        onCancel: () => {
          dispatch(showAlert(false));
        },
        okText: t('Tentar novamente'),
        onOk: () => {
          window.open(
            `https://wa.me//${process.env.REACT_APP_WHATSAPP_NUMBER}?text=Tenho%20em%20atualizzar%20meu%20plano%20`,
          );
        },
      }),
    );
  };

  return (
    <div className={`home-page background-page ${collapsed ? '' : 'with-menu-opened'}`}>
      <div className="home-content scroll-content">
        {listId && (
          <div className="container-btn-back">
            <Button
              icon="arrow-back"
              className="outline-light btn-back"
              onClick={() => navigate(`/planner-lists/${listId}/card`)}
            >
              {t('Voltar ao planejamento')}
            </Button>
          </div>
        )}

        <Header
          title={t(listId ? 'Influenciadores do planejamento' : 'Busca de influenciadores')}
          className="pl-header"
        ></Header>

        {!listId && (
          <>
            <div className="home-search">
              {user.features.includes('unlimited-search') ||
              (!user.features.includes('unlimited-search') && percentageQuota < 100) ? (
                <SearchInfluencer />
              ) : (
                <div className="container-search-limit-exceeded">
                  <div className="box-search-limit-exceeded">
                    <div className="left-side">
                      <i className="icon icon-alert" />

                      <p>
                        {t('Você atingiu o limite de buscas para o seu plano ')}

                        <span>
                          {t('Para fazer mais pesquisas fale com um de nossos especialistas.')}
                        </span>

                        <span className="date-text">
                          {t('Suas buscas renovam em')}:{' '}
                          {moment(slice.renewQuotaDate).format('DD [de] MMM[.] YYYY')}.
                        </span>
                      </p>
                    </div>

                    <Button
                      onClick={() => {
                        dispatch(
                          changeObjAlert({
                            show: true,
                            type: 'info',
                            title: t('Altere seu plano.'),
                            text: t('Entre em contato com a gente pelo WhatsApp para continuar.'),
                            //
                            cancelText: t('Fechar'),
                            onCancel: () => {
                              dispatch(showAlert(false));
                            },
                            okText: t('Chamar no WhatsApp'),
                            onOk: () => {
                              dispatch(showAlert(false));

                              window.open(
                                `https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}&text=Tenho%20interesse%20em%20entender%20o%20plano%20ilimitado%20do%20Airsearch`,
                              );

                              tryAgainOpenWpp();
                            },
                          }),
                        );
                      }}
                    >
                      {t('Falar com especialista')}
                    </Button>
                  </div>
                </div>
              )}

              <div className="container-recommendations">
                <div className="recommendations">
                  <div className={`slider slider-selected-${selectDot}`}>
                    { user.features.includes('workspace') &&  <WorkspaceRecommendation /> }
                    
                    { user.features.includes('aira') && <AiraRecommendation
                      onClick={() => updateSelectDot(1)}
                      enableOnClick={selectDot !== 1}
                    /> }

                    <ExtensionRecommendation
                      onClick={() => updateSelectDot(2)}
                      enableOnClick={selectDot !== 2}
                    />
                  </div>

                  <div className="dots">
                    <span
                      className={`hover ${selectDot === 1 ? 'active' : ''}`}
                      onClick={() => updateSelectDot(1)}
                    />

                    <span
                      className={`hover ${selectDot === 2 ? 'active' : ''}`}
                      onClick={() => updateSelectDot(2)}
                    />

                     <span
                      className={`hover ${selectDot === 3 ? 'active' : ''}`}
                      onClick={() => updateSelectDot(3)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="home-list-container">
          <div className="home-list">
            <div className="list-influencers">
              <InfluencerList listId={listId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.propTypes = {
  listId: PropTypes.string,
};

export default Home;
