import api from '../../../service/api';

export class WorkspaceService {
  static campaigns = {
    create(data) {
      return api.post('/workspace', data);
    },
    update(campaignId, data) {
      return api.put(`/workspace/${campaignId}`, data);
    },
    getAll(page = 0) {
      return api.get(`/workspace/list/${page}`);
    },
    getById(campaignId) {
      return api.get(`/workspace/${campaignId}`);
    },
    delete(campaignId) {
      return api.delete(`/workspace/${campaignId}`);
    },
    duplicate(campaignId, data) {
      return api.post(`/workspace/${campaignId}/duplicate`, data);
    },
  };

  static columns = {
    create(campaignId, data) {
      return api.post(`/workspace/${campaignId}/column`, data);
    },

    update(campaignId, columnId, data) {
      return api.put(`/workspace/${campaignId}/column/${columnId}`, data);
    },

    delete(campaignId, columnId) {
      return api.delete(`/workspace/${campaignId}/column/${columnId}`);
    },

    changePosition(campaignId, columnId, order) {
      return api.put(`/workspace/${campaignId}/column/${columnId}/move`, { order });
    },
  };

  static profiles = {
    add(campaignId, data) {
      return api.post(`/workspace/${campaignId}/profile`, data);
    },

    delete(campaignId, profileId) {
      return api.delete(`/workspace/${campaignId}/profile/${profileId}`);
    },

    changePosition(campaignId, profileId, data) {
      return api.put(`/workspace/${campaignId}/profile/${profileId}/move`, data);
    },

    update(campaignId, profileId, data) {
      return api.put(`/workspace/${campaignId}/profile/${profileId}`, data);
    },

    search(data) {
      return api.post('/profile/search', {
        items_per_page: 16,
        page: data.page,
        query: data.query,
      });
    },
  };

  static posts = {
    list(proifleId, page, itemsPerPage) {
      return api.post('/profile/posts', {
        profile_id: proifleId,
        page,
        items_per_page: itemsPerPage,
      });
    },
  };

  static demographics = {
    get(profileId, username, network) {
      return api.get(`/demographics/${username}/${profileId}/${network}`);
    },
  };

  static report = {
    profileData(campaignId) {
      return api.get(`/workspace/${campaignId}/report/profiles`);
    },
    postsData(campaignId) {
      return api.get(`/workspace/${campaignId}/report/posts`);
    },
    togglePostData(campaignId, metricsPostId, metricsProfileId ) {
      return api.post(`/workspace/${campaignId}/post/toggle` , { metrics_post_id: metricsPostId, metrics_profile_id: metricsProfileId }  );
    }
  };
}
