import Button from '../../../../common/components/Button';
import HeaderComponent from '../../../../common/components/Header';
// import Input from '../../../../common/components/Input';
import { useModal } from '../contexts/ModalProvider';
// import { useWorkspaces } from '../contexts/WorkspacesProvider';
import { CreateCampaignModal } from './CreateCampaignModal';

export const Header = () => {
  const { openModal } = useModal();
  // const { search, handleChangeSearch } = useWorkspaces();


  return (
    <>
      <HeaderComponent title="Áreas de trabalho" />

      <div>
        {/* <Input
          className="input-name"
          prefix={<i className="icon icon-search" />}
          placeholder="Pesquise por palavras-chave ou nomes das áreas de trabalho"
          value={search}
          onChange={handleChangeSearch}
        /> */}
        <Button
          icon="plus"
          className="pattern-dark"
          onClick={() => openModal(<CreateCampaignModal />)}
        >
          Área de trabalho
        </Button>
        <Button> Pesquisar</Button>
      </div>
    </>
  );
};
