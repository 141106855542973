import { Board } from './components/Board';
import { Header } from './components/Header';
import { Modal } from './components/Modal';
import { Report } from './components/Report';
import { CampaignProvider, useCampaign } from './contexts/CampaignProvider';
import { ModalProvider } from './contexts/ModalProvider';

import './styles.scss';

export const Campaign = () => {
  return (
    <CampaignProvider>
      <ModalProvider>
        <BoardWrapper />
        <Modal />
      </ModalProvider>
    </CampaignProvider>
  );
};

const BoardWrapper = () => {
  const { tabs } = useCampaign();

  return (
    <div className="background-page rm">
    <div className="container_rm">
      <Header />

      {tabs === 'influencers' && <Board />}
      {tabs === 'reports' && <Report />}
    </div>
  </div>

  )
}
