import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';

import { useDebounce } from '../../../../hooks/useDebounce';
import { useRequest } from '../../../../hooks/useRequest';
import { WorkspaceService } from '../../Campaign/service';

export const WorkspacesContext = createContext({});

export const WorkspacesProvider = ({ children }) => {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);

  const { data, setData } = useRequest(() => WorkspaceService.campaigns.getAll(0), {
    deps: [],
  });

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const filteredWorkspaces = {
    ...data,
    items: data?.items?.filter((workspace) =>
      workspace.title.toLowerCase().includes(debouncedSearch.toLowerCase()),
    ),
  };

  return (
    <WorkspacesContext.Provider
      value={{
        search,
        handleChangeSearch,
        workspaces: filteredWorkspaces,
        setWorkspaces: setData,
      }}
    >
      {children}
    </WorkspacesContext.Provider>
  );
};

export const useWorkspaces = () => {
  const context = useContext(WorkspacesContext);

  if (!context) {
    throw new Error('useWorkspaces must be used within a WorkspacesContext');
  }

  return context;
};

WorkspacesProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
