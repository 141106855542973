import React from "react"
// 
import loading from "../../assets/gifs/loading.gif"
import PropTypes from "prop-types"

const Loading = () => (
    <div className="container-loading" style={{ margin: "50px auto 10px", textAlign: "center" }}>
        <img src={loading} alt="ícone de carregamento" style={{ width: "60px" }} />
    </div>
)

const IsLoading = ({ children, isLoading }) => isLoading ? <Loading /> : children

IsLoading.defaultProps = {
    isLoading: false
}
IsLoading.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,  // any valid React element, like a component or a string of text.
}
export {IsLoading}
export default Loading