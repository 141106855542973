import { zodResolver } from '@hookform/resolvers/zod';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

import Button from '../../../../../common/components/Button';
import feedback from '../../../../../common/components/Feedback';
import Input from '../../../../../common/components/Input';
import Modal from '../../../../../common/components/Modal';
import { useMutation } from '../../../../../hooks/useMutation';
import { formatDate } from '../../../../../utils/formatters';
import { maskDate } from '../../../../../utils/masks';
import { InputTags } from '../../../Workspaces/components/InputTags';
import { useCampaign } from '../../contexts/CampaignProvider';
import { useModal } from '../../contexts/ModalProvider';
import { WorkspaceService } from '../../service';
import { SuccessfullyDuplicatedCampaignModal } from '../SuccessfullyDuplicatedCampaignModal';
import { schema } from './schema';

import './styles.scss';

export const DuplicateCampaignModal = ({ row }) => {
  const { isOpen, closeModal, openModal } = useModal();
  const { campaignId } = useCampaign();

  const mutation = useMutation(WorkspaceService.campaigns.duplicate);

  const {
    formState: { errors },
    ...form
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      title: row.title,
      tags: {
        hashtags: row.hashtags,
        mentions: row.mentions,
      },
      start_date: formatDate(row.start_date),
      end_date: formatDate(row.end_date),
    },
  });

  const handleSubmit = form.handleSubmit(async (formData) => {
    try {
      const { data } = await mutation.mutateAsync(campaignId, {
        title: formData.title,
        hashtags: formData.tags.hashtags,
        mentions: formData.tags.mentions,
        start_date: formData.start_date,
        end_date: formData.end_date,
      });

      openModal(<SuccessfullyDuplicatedCampaignModal campaignId={data.uid} />);
    } catch {
      feedback.error('Falha ao duplicar campanha.');
    }
  }, console.log);

  return (
    <Modal
      title="Duplicar Área de Trabalho"
      subtitle="Edite ou atualize as informações de campanha da sua Área de Trabalho."
      isVisible={isOpen}
      onClose={closeModal}
      className="create_campaign_modal"
    >
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="title">Nome da Área de Trabalho</label>
          <Controller
            name="title"
            control={form.control}
            render={({ field }) => (
              <Input
                placeholder="Novos Influenciadores de culinária"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {errors.title && <span>{errors.title.message}</span>}
        </div>
        <div>
          <label htmlFor="tags">Hashtags e menções</label>
          <p>Insira abaixo as #hashtags que deseja monitorar no período da campanha.</p>
          <Controller
            name="tags"
            control={form.control}
            render={({ field }) => (
              <InputTags
                placeholder="#airfluencers @airfluencers"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          {errors.tags && <span>{errors.tags.message}</span>}
        </div>
        <div>
          <b>Período</b>
          <div>
            <div>
              <label htmlFor="start_date">Início</label>
              <Controller
                name="start_date"
                control={form.control}
                render={({ field }) => (
                  <Input
                    placeholder="DD/MM/AAAA"
                    value={field.value}
                    onChange={(event) => {
                      const maskedValue = maskDate(event.target.value);
                      field.onChange(maskedValue);
                    }}
                  />
                )}
              />
              {errors.start_date && <span>{errors.start_date.message}</span>}
            </div>
            <div>
              <label htmlFor="end_date">Final</label>
              <Controller
                name="end_date"
                control={form.control}
                render={({ field }) => (
                  <Input
                    placeholder="DD/MM/AAAA"
                    value={field.value}
                    onChange={(event) => {
                      const maskedValue = maskDate(event.target.value);
                      field.onChange(maskedValue);
                    }}
                  />
                )}
              />
              {errors.end_date && <span>{errors.end_date.message}</span>}
            </div>
          </div>
        </div>

        <footer>
          <Button type="button" onClick={closeModal}>
            Cancelar
          </Button>
          <Button type="submit" onClick={handleSubmit}>
            Criar
          </Button>
        </footer>
      </form>
    </Modal>
  );
};

DuplicateCampaignModal.propTypes = {
  row: PropTypes.object,
};
