import { formatCurrency } from './formatters';

export const maskDate = (value) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4}).*/, '$1');
};

export const maskCurrency = (value) => {
  const numericValue = String(value).replace(/\D/g, '');

  return formatCurrency(Number(numericValue / 100));
};
