import { Plus } from 'lucide-react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import feedback from '../../../../common/components/Feedback';
import { useCampaign } from '../contexts/CampaignProvider';
import { useModal } from '../contexts/ModalProvider';
import { WorkspaceService } from '../service';
import { AddColumnModal } from './AddColumnModal';
import { Column } from './Column';
import Loading from '../../../../common/components/Loading';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const Board = () => {
  const { campaign, setCampaign, campaignId, isLoading } = useCampaign();
  const { openModal } = useModal();

  const handleOpenAddColumnModal = () => {
    openModal(<AddColumnModal />);
  };

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const { source, destination, type, draggableId } = result;

    const lastIndex = campaign.columns.length - 1;

    if (type === 'COLUMN' && (source.index === lastIndex || destination.index === lastIndex)) {
      feedback.error('Esta coluna não pode ser movida para o final.');
      return;
    }

    const changeProfilePosition = () =>
      WorkspaceService.profiles.changePosition(campaignId, draggableId, {
        column_uid: destination.droppableId,
        order: destination.index,
      });

    if (type === 'COLUMN') {
      // Reordenar colunas
      const reorderedColumns = reorder(campaign.columns, source.index, destination.index);

      const oldCampaign = campaign;

      try {
        setCampaign((prev) => ({
          ...prev,
          columns: reorderedColumns,
        }));

        await WorkspaceService.columns.changePosition(campaignId, draggableId, destination.index);
        feedback.success('Coluna movida com sucesso.');
      } catch {
        feedback.error('Falha ao mover coluna.');

        setCampaign(oldCampaign);
      }
    } else if (source.droppableId === destination.droppableId) {
      // Reordenar perfis dentro da mesma coluna
      const column = campaign?.columns.find((c) => c.uid === source.droppableId);

      if (column) {
        const reorderedProfiles = reorder(column.cards, source.index, destination.index);
        const updatedColumn = { ...column, cards: reorderedProfiles };

        const oldCampaign = campaign;

        try {
          setCampaign((prev) => ({
            ...prev,
            columns: prev.columns.map((c) => (c.uid === column.uid ? updatedColumn : c)),
          }));

          await changeProfilePosition();
        } catch (error) {
          feedback.error('Falha ao mover perfil.');
          setCampaign(oldCampaign);
        }
      }
    } else {
      // Mover perfil entre colunas diferentes
      const sourceColumn = campaign.columns.find((c) => c.uid === source.droppableId);
      const destColumn = campaign.columns.find((c) => c.uid === destination.droppableId);

      if (sourceColumn && destColumn) {
        const sourceProfiles = Array.from(sourceColumn.cards);
        const destProfiles = Array.from(destColumn.cards);

        const [movedProfile] = sourceProfiles.splice(source.index, 1);
        destProfiles.splice(destination.index, 0, movedProfile);

        const oldCampaign = campaign;

        try {
          setCampaign((prev) => ({
            ...prev,
            columns: prev.columns.map((c) => {
              if (c.uid === sourceColumn.uid) {
                return { ...sourceColumn, cards: sourceProfiles };
              } else if (c.uid === destColumn.uid) {
                return { ...destColumn, cards: destProfiles };
              } else {
                return c;
              }
            }),
          }));

          await changeProfilePosition();
        } catch {
          feedback.error('Falha ao mover perfil.');
          setCampaign(oldCampaign);
        }
      }
    }
  };

  const columns = campaign?.columns ?? [];
  
  return (
    <div className="board_rm">
      {!isLoading ? (
        <>
          <button
            className="button__board_rm"
            onClick={handleOpenAddColumnModal}
            data-size={columns.length > 1 ? 'icon' : 'default'}
          >
            <Plus />
            <span>Adicionar lista</span>
          </button>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="board" type="COLUMN" direction="horizontal">
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} className="columns_rm">
                  {columns.map((column, index) => (
                    <Column key={column.uid} column={column} index={index} />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      ) : (
        <div className="board_rm_loading">
          <Loading />
        </div>
      )}
    </div>
  );
};
