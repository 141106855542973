import React, { useEffect } from 'react';
import { useCampaign } from '../contexts/CampaignProvider';
import { useTranslation } from 'react-i18next';
import BarChart from '../../../../common/components/Charts/BarChart';
import Select from '../../../../common/components/Select';
import Tooltip from 'antd/es/tooltip';
import { ExternalLink } from 'lucide-react';
import { buildValue, formatPercentage, humanize } from '../../../../utils/formatters';
import { Toggle } from './Toggle';
import { WorkspaceService } from '../service';
import feedback from '../../../../common/components/Feedback';
import moment from 'moment';
import { IsLoading } from '../../../../common/components/Loading';
import Connect from './Connect';
import AvatarNetwork from '../../../../common/components/AvatarNetwork';
import { useNavigate } from 'react-router-dom';
// import { notification } from 'antd';

const colorPalette = [
  '#C5E1A5', // Light Green
  '#FFCC80', // Light Orange
  '#81D4FA', // Light Blue
  '#B39DDB', // Light Purple
  '#F48FB1', // Light Pink
  '#FFB74D', // Orange
  '#4DB6AC', // Teal
  '#7986CB', // Indigo
  '#FF8A65', // Deep Orange
  '#A1887F', // Brown
  '#9CCC65', // Lime
  '#4DD0E1', // Cyan
  '#BA68C8', // Purple
  '#4FC3F7', // Light Blue (darker shade)
  '#FFF176', // Yellow
  '#64B5F6', // Blue
  '#81C784', // Green
  '#DCE775', // Lime (lighter shade)
  '#FFD54F', // Amber
  '#FF8A80', // Red (lighter shade)
  '#90A4AE', // Blue Grey
  '#F06292', // Pink (darker shade)
  '#7CB342', // Light Green (darker shade)
  '#FFA726', // Orange (darker shade)
  '#78909C', // Blue Grey (darker shade)
];

const options = [
  {
    value: 'cost_per_view',
    key: 'Custo por Visualização (CPV)',
  },
  {
    value: 'cost_per_reach',
    key: 'Custo por Alcance (CPA)',
  },
  {
    value: 'engagement_rate',
    key: 'Taxa de Engajamento (TXE)',
  },
  {
    value: 'interactions',
    key: 'Interações',
  },
  {
    value: 'views',
    key: 'Visualizações',
  },
  {
    value: 'likes',
    key: 'Curtidas',
  },
  {
    value: 'comments',
    key: 'Comentários',
  },
  {
    value: 'reach',
    key: 'Alcance',
  },
  {
    value: 'total_posts',
    key: 'Número de Publicações',
  }
];

const getRandomColor = () => colorPalette[Math.floor(Math.random() * colorPalette.length)];
export const Report = () => {
  const { campaignId } = useCampaign();
  const { t } = useTranslation();
  const [metric, setMetric] = React.useState('views');
  const [influencers, setInfluencers] = React.useState({ status: 'loading', data: [] });
  const [posts, setPosts] = React.useState({ status: 'loading', data: [] });
  const navigate = useNavigate()

  useEffect(() => {
    WorkspaceService.report
      .profileData(campaignId)
      .then((response) => {
        console.log(response.data.profiles)
        const reportProfiles = response.data.profiles.map((item) => ({
          id: item.data.id,
          network: item.data.network,
          name: item.data.name,
          username: item.data.username,
          avatar: item.data.picture,
          followers: item.data.counters.followers,
          story: item.story,
          video: item.video,
          reel: item.reel,
          post: item.post,
          reach: item.reach,
          total_posts: item.story + item.video + item.reel + item.post,
          interactions: item.interactions,
          contract: {
            posts: item.profile_card.posts_count,
            reel: item.profile_card.reels_count,
            story: item.profile_card.stories_count,
            video: item.profile_card.videos_count,
            investment_total:
              item.profile_card.reels_price +
              item.profile_card.videos_price +
              item.profile_card.stories_price +
              item.profile_card.posts_price,
          },
          engagement_rate: parseFloat((item.engagement_rate * 100).toFixed(2)),
          connect_status: item.data.connect_status,
          views: item.views,
          post_metrics: (item.workspace_report_profiles_metrics || []).map((pMetrics) => ({
            total_media: pMetrics.total_media,
            engagement_rate: parseFloat((pMetrics.engagement_rate * 100).toFixed(2)),
            post_type: pMetrics.post_type,
            comments: pMetrics.comments,
            cost_per_comment: pMetrics.cost_per_comment,
            cost_per_like: pMetrics.cost_per_like,
            views: pMetrics.views,
            cost_per_view: pMetrics.cost_per_view,
            cost_per_reach: pMetrics.cost_per_reach,
            likes: pMetrics.likes,
            reach: pMetrics.reach,
          })),
          cost_per_view: item.cost_per_view.toFixed(2),
          cost_per_reach: item.cost_per_reach.toFixed(2),
          likes: item.likes,
          comments:
            item.comments,
        }));
        setInfluencers({ status: 'completed', data: reportProfiles });
      })
      .catch(() => {
        feedback.error('Error ao carregar dados do relatório');
      });
  }, []);

  useEffect(() => {
    WorkspaceService.report
      .postsData(campaignId)
      .then((response) => {
        const reportPosts = response.data.items.map((item) => ({
          id: item.metrics_post_id,
          thumb: item.data.thumbnail,
          type: item.data.type,
          post_id: item.data.post_id,
          reach: item.data.counters.reach,
          views: item.data.counters.views,
          interactions: item.data.counters.interactions,
          likes: item.data.counters.likes,
          comments: item.data.counters.comments,
          permalink: item.data.permalink,
          influencer: {
            metricsId: item.profile.data.id,
            network: item.data.network,
            name: item.profile.data.name,
            username: item.profile.data.username,
            avatar: item.profile.data.picture,
          },
          engagement_rate: parseFloat((item.data.engagement_rate * 100).toFixed(2)),
          date: moment(item.data.posted_at).format('DD/MM/YYYY'),
          inCampaign: true,
        }));
        setPosts({ status: 'completed', data: reportPosts });
      })
      .catch(() => {
        feedback.error('Error ao carregar dados dos posts');
      });
  }, []);

  const handleToggle = (metricsProfileId, metricsPostId) => {
    WorkspaceService.report.togglePostData(campaignId, metricsPostId, metricsProfileId).then(() => {
      const newPosts = posts.data.map((post) => {
        if (post.id === metricsPostId) {
          return  {...post, inCampaign:!post.inCampaign };
        } 
        return {...post}
      })

      setPosts({...posts, data: newPosts });
      feedback.success('Status alterado com sucesso');
    }).catch(() => {
      feedback.error('Erro ao alterar status do post');
    })
  }

  return (
    <div className="report_root">
      <div className="interaction-chart chart-posts-interval" key="chart-posts-interval">
        <div className="subtitle-filter-category">
          <p className="subtitle-part">{t('Compare os Influenciadores')}</p>
          <Select
          style={{ width: '250px' }}
            value={metric}
            placeholder={t('Selecione uma métrica')}
            onChange={(value) => setMetric(value)}
            options={options}
          />
        </div>

        <IsLoading isLoading={influencers.status !== 'completed'}>
          {influencers.data && influencers.data.length > 0 ? (
            <BarChart
              formatType="number"
              data={influencers.data.map((item) => ({
                label: item.name,
                value: item[metric],
                color: getRandomColor(),
              }))}
            />
          ) : (
            <div className="empty-data">Ainda não existem perfis em campanha.</div>
          )}
        </IsLoading>
      </div>

      <div className="interaction-chart">
        <div className="subtitle-filter-category">
          <p className="subtitle-part">{t('Tabela Comparativa')} ({influencers.data ? influencers.data.length : 0})</p>
        </div>
        <IsLoading isLoading={influencers.status !== 'completed'}>
          <div className="influencer-table-container">
            {influencers.data && influencers.data.length > 0 ? (
              <div className="container-table">
                <table>
                  <thead>
                    <tr>
                      <th>{t('Influenciadores')}</th>
                      <th>{t('Conecte')}</th>
                      <th>{t('Seguidores')}</th>
                      <th>{t('Investimento')}</th>
                      <th>{t('Visualizações')}</th>
                      <th>{t('CPV')}</th>
                      <th>{t('Engajamento')}</th>
                      <th>{t('Stories')}</th>
                      <th>{t('Reels')}</th>
                      <th>{t('Videos')}</th>
                      <th>{t('Posts')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {influencers.data.map((influencer, index) => (
                      <tr key={index} className="influencer-row">
                        <td className="td-influencer">
                          <div className="div-flex">
                            <AvatarNetwork onClick={() => navigate(`/card/${influencer.id}`)} size={50} networkSize={16} network={influencer.network} src={influencer.avatar} />

                            <div className="name-username">
                              <Tooltip title={influencer.name}>
                                <span className="name">{influencer.name}</span>
                              </Tooltip>

                              <div className="username hover">
                                <span>@{influencer.username}</span>

                                <i className="icon icon-external-link" />
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="td-influencer td-inlfuencer-connect">
                          <Connect isConnected={influencer.connect_status === 'connected'} network={influencer.network} />
                        </td>
                        <td>
                          <p>{humanize(influencer.followers || 0)}</p>
                        </td>

                        <td>
                          <p>{buildValue('currency', influencer.contract.investment_total || 0)}</p>
                        </td>

                        <td>
                          <p>{humanize( influencer.views || 0)}</p>
                        </td>

                        <td>
                          <p>{buildValue('currency', influencer.cost_per_view || 0)}</p>
                        </td>

                        <td>
                          <p>{formatPercentage(influencer.engagement_rate / 100)}</p>
                        </td>
                        <td>
                          <p>
                            {influencer.story}/{influencer.contract.story}
                          </p>
                        </td>
                        <td>
                          <p>
                            {influencer.reel}/{influencer.contract.reel}
                          </p>
                        </td>
                        <td>
                          <p>
                            {influencer.video}/{influencer.contract.video}
                          </p>
                        </td>

                        <td>
                          <p>
                            {influencer.post}/{influencer.contract.posts}
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="empty-data">Ainda não existem perfis em campanha.</div>
            )}
          </div>
        </IsLoading>
      </div>

      <div className="interaction-chart">
        <div className="subtitle-filter-category">
          <p className="subtitle-part">{t('Publicacões da Campanha')} ({posts.data ? posts.data.length : 0})</p>
        </div>
        <IsLoading isLoading={posts.status !== 'completed'}>
          {posts.data && posts.data.length > 0 ? (
            <div className="influencer-table-container">
              <div className="container-table">
                <table>
                  <thead>
                    <tr>
                      <th>{t('Conteúdo')}</th>
                      <th>{t('Influenciador')}</th>
                      <th>{t('Data')}</th>
                      <th>{t('Alcance')}</th>
                      <th>{t('Visualizações')}</th>
                      <th>{t('Interações')}</th>
                      <th>{t('Curtidas')}</th>
                      <th>{t('Comentários')}</th>
                      <th>{t('Na Campanha')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {posts.data.map((post, index) => (
                      <tr key={index} className="influencer-row">
                        <td
                          className="content-thumb-col"
                          onClick={() => window.open(post.permalink, '_blank')}
                          style={{ textAlign: 'left' }}
                        >
                          <div className="influencer-post-content">
                            <div
                              className="content-thumb"
                              style={{ backgroundImage: `url(${post.thumb})` }}
                            />

                            <ExternalLink
                              style={{ color: 'white' }}
                              size={18}
                              absoluteStrokeWidth
                            />
                          </div>
                          {post.type || 'Story'}
                        </td>
                        <td>
                          <div className="influencer-post-avatar">
                          <AvatarNetwork size={50} networkSize={16} network={post.influencer.network} src={post.influencer.avatar} />
                            {post.influencer.name}
                          </div>
                        </td>
                        <td>{post.date}</td>
                        <td>{humanize(post.reach)}</td>
                        <td>{humanize(post.views)}</td>
                        <td>{humanize(post.interactions)}</td>
                        <td>{humanize(post.likes)}</td>
                        <td>{humanize(post.comments)}</td>
                        <td>
                          <Toggle handleToggle={() => handleToggle(post.influencer.metricsId, post.id)} initialState={post.inCampaign} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="empty-data">Ainda não existem posts na campanha.</div>
          )}
        </IsLoading>
      </div>
    </div>
  );
};
