import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
//
import { Layout, Menu, Progress } from 'antd';
//
import { useDispatch, useSelector } from 'react-redux';
import { selectAppSlice } from '../../../app/slice';
import { selectHomeSlice } from '../../../features/private/Home/slice';
import {
  fetchNotifications,
  selectNotificationsSlice,
} from '../../../features/private/Notifications/slice';
import { selectMenuSlice, updateCollapsed } from './slice';
//
import Notifications from '../../../features/private/Notifications';
import Button from '../Button';
import TranslateModal from '../TranslateModal';
//
import logoSymbol from '../../assets/icons/symbol-airfluencers.svg';
import aira from '../../assets/images/aira.png';
import workspaceIcon from '../../assets/images/workspace.svg';
import logoName from '../../assets/images/tipografia_airsearch.svg';
//
import { changeObjAlert, showAlert } from '../Alert/slice';
import './index.scss';

const { Sider } = Layout;

const SideMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //
  const menu = useSelector(selectMenuSlice);
  const appSlice = useSelector(selectAppSlice);
  const homeSlice = useSelector(selectHomeSlice);
  const sliceNotifications = useSelector(selectNotificationsSlice);
  //
  const user = appSlice.user;
  const collapsed = menu.collapsed;
  const counter = sliceNotifications.totalCounters;
  const percentageQuota = homeSlice.percentageQuota;
  //
  const [current, updateCurrent] = useState('influencer-list');
  const [showTranslateModal, updateShowTranslateModal] = useState(false);
  const [showNotificationModal, updateShowNotificationModal] = useState(false);
  //
  const getItem = (label, key, icon, children, type) => {
    return { label, key, icon, children, type };
  };
  //
  const menuTopItems = [
    getItem(
      t('Buscar influenciadores'),
      'influencer-list',
      <i className="icon icon-search-profile" />,
    ),
    getItem(t('Lista de planejamento'), 'planners', <i className="icon icon-list" />),
    getItem(t('Solicitações'), 'add', <i className="icon icon-circle-plus" />, [
      getItem(t('Adicionar influenciador'), 'add-influencer'),
      getItem(t('Categoria'), 'add-theme'),
      getItem(t('Marca'), 'add-brand'),
    ]),
    user.features.includes('workspace') ? getItem(
      t('Área de Trabalho'),
      'workspaces',
      <img width={17} src={workspaceIcon} alt="Área de trabalho" />,
    )
  : false,
    user.features.includes('aira')
      ? getItem(
          t('Assistente de Curadoria'),
          'aira',
          <img src={aira} alt="mulher  do cabelo preto sorrindo" />,
        )
      : false,
  ].filter((item) => item);

  const closeNotificationModal = () => {
    const modal = document.querySelector('.notification-modal');

    if (modal) {
      modal.classList.add('closed');

      setTimeout(() => {
        updateShowNotificationModal(false);
      }, 250);
    }
  };

  const toggle = () => {
    const limitedSearch = document.querySelector('.limited-search');

    dispatch(updateCollapsed(!collapsed));
    localStorage.setItem('state_menu', !collapsed);

    if (limitedSearch) {
      limitedSearch.setAttribute('style', 'opacity: 0');

      setTimeout(() => {
        limitedSearch.setAttribute('style', 'opacity: 1');
      }, 1000);
    }
  };

  const runAction = (key = '') => {
    if (key === 'influencer-list') {
      updateCurrent(key);
      navigate('/');
    } else if (key === 'planners') {
      updateCurrent(key);
      navigate('/planner-lists');
    } else if (key === 'campaigns') {
      window.location.href = process.env.REACT_APP_ANALYTICS_URL;
    } else if (key === 'add-influencer') {
      updateCurrent(key);
      navigate('/add-influencer');
    } else if (key === 'add-theme') {
      updateCurrent(key);
      navigate('/dataset/theme');
    } else if (key === 'add-brand') {
      updateCurrent(key);
      navigate('/dataset/brand');
    } else if (key === 'aira') {
      navigate('/aira');
    } else if (key === 'settings') {
      window.open(process.env.REACT_APP_HUB_URL + 'settings/user/', '_blank');
    } else if (key === 'help') {
      window.location.href = 'https://intercom.help/airfluencersapp/pt-BR/';
    } else if (key === 'exit') {
      window.localStorage.removeItem('access_token');
      window.localStorage.removeItem('refresh_token');

      setTimeout(() => {
        window.location.href = process.env.REACT_APP_HUB_URL + 'logout';
      }, 500);
    } else if (key === 'workspaces') {
      navigate('/workspaces');
    }

    if (key === 'notifications') {
      if (!showNotificationModal) {
        updateShowNotificationModal(true);
      } else {
        closeNotificationModal();
      }
    } else {
      closeNotificationModal();
    }
  };

  const tryAgainOpenWpp = () => {
    dispatch(
      changeObjAlert({
        show: true,
        type: 'success',
        title: t('Sucesso!'),
        text: t('Você foi redirecionado para o WhatsApp.'),
        //
        cancelText: t('Fechar'),
        onCancel: () => {
          dispatch(showAlert(false));
        },
        okText: t('Tentar novamente'),
        onOk: () => {
          window.open(
            `https://wa.me//${process.env.REACT_APP_WHATSAPP_NUMBER}?text=Tenho%20em%20atualizzar%20meu%20plano%20`,
          );
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  useEffect(() => {
    dispatch(updateCollapsed(localStorage.getItem('state_menu') == 'true'));
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      updateCurrent('influencer-list');
    } else if (location.pathname === '/planner-lists') {
      updateCurrent('planners');
    } else if (location.pathname.includes('add-influencer')) {
      updateCurrent('add-influencer');
    } else if (location.pathname.includes('dataset/theme')) {
      updateCurrent('add-theme');
    } else if (location.pathname.includes('dataset/brand')) {
      updateCurrent('add-brand');
    } else {
      updateCurrent();
    }
  }, [location]);

  return (
    <>
      <div
        role="button"
        onClick={() => {
          const limitedSearch = document.querySelector('.limited-search');

          dispatch(updateCollapsed(!collapsed));
          window.localStorage.setItem('state_menu', !collapsed);

          if (limitedSearch) {
            limitedSearch.setAttribute('style', 'display: none');

            setTimeout(() => {
              limitedSearch.setAttribute('style', 'display: block');
            }, 400);
          }
        }}
        className={`menu-arrow ${collapsed ? 'collapsed' : ''}`}
      >
        <i className={`icon icon-${collapsed ? 'advance' : 'back'}`} />
      </div>

      <Sider
        collapsible
        width={210}
        trigger={null}
        onCollapse={toggle}
        collapsed={collapsed}
        style={{
          left: 0,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <div className="logo-airfluencer">
          <img className="logo" alt="logo airfluencer" src={logoSymbol} />

          <div className="logo-label">
            <img className="text-img" src={logoName} alt="icon airfluencer" />
          </div>
        </div>

        <div className="menus">
          <Menu
            theme="dark"
            mode="inline"
            className="top-menu"
            defaultSelectedKeys={current}
            selectedKeys={[current]}
            items={menuTopItems}
            onClick={({ key }) => runAction(key)}
          />

          <Menu
            theme="dark"
            mode="inline"
            className="bottom-menu"
            defaultSelectedKeys={current}
            selectedKeys={current}
            items={[
              !user.features.includes('unlimited-search') ? (
                getItem(
                  collapsed ? (
                    <div>
                      <b>{t('Plano Light')}</b>
                      <br />
                      <span>{t('Buscas realizadas')}</span>
                    </div>
                  ) : (
                    ''
                  ),
                  'limited-search',
                  <div
                    className={`limited-search ${collapsed ? '' : 'opened'} ${
                      percentageQuota === 100 ? 'quota-exceeded' : ''
                    }`}
                  >
                    {!collapsed && <p className="plan-text">{t('Plano Light')}</p>}

                    <Progress
                      size="small"
                      format={(p) => `${p}%`}
                      trailColor="#D9D9D9"
                      percent={percentageQuota}
                      type={collapsed ? 'circle' : 'line'}
                      showInfo={true}
                      success={
                        percentageQuota === 100 && {
                          percent: percentageQuota,
                          strokeColor: '#CE7B0A',
                        }
                      }
                      strokeColor="#ED276A"
                    />

                    {!collapsed && (
                      <p className="search-concluded-text">{t('Buscas realizadas')}</p>
                    )}

                    {percentageQuota === 100 && (
                      <div className="part-speak-to-specialist">
                        <p>{t('Deseja realizar mais buscas?')}</p>

                        <Button
                          onClick={() => {
                            dispatch(
                              changeObjAlert({
                                show: true,
                                type: 'info',
                                title: t('Altere seu plano.'),
                                text: t(
                                  'Entre em contato com a gente pelo WhatsApp para continuar.',
                                ),
                                //
                                cancelText: t('Fechar'),
                                onCancel: () => {
                                  dispatch(showAlert(false));
                                },
                                okText: t('Chamar no WhatsApp'),
                                onOk: () => {
                                  dispatch(showAlert(false));

                                  window.open(
                                    `https://wa.me//${process.env.REACT_APP_WHATSAPP_NUMBER}?text=Tenho%20em%20atualizzar%20meu%20plano%20`,
                                  );

                                  tryAgainOpenWpp();
                                },
                              }),
                            );
                          }}
                        >
                          {t('Falar com especialista')}
                        </Button>
                      </div>
                    )}
                  </div>,
                )
              ) : (
                <></>
              ),
              getItem(
                t('Notificações'),
                'notifications',
                <div className="notification-counter">
                  <i className="icon icon-notifications" />

                  {counter !== '0' && counter !== 0 && <div className="counter">{counter}</div>}
                </div>,
              ),
              getItem(t('Configurações'), 'settings', <i className="icon icon-settings" />),
              getItem(t('Ajuda'), 'help', <i className="icon icon-tooltip" />),
              getItem(t('Sair'), 'exit', <i className="icon icon-logout1" />),
            ]}
            onClick={({ key }) => runAction(key)}
          />
        </div>
      </Sider>

      {showNotificationModal && (
        <div
          className={`notification-modal ${collapsed ? 'collapsed' : ''}`}
          onClick={() => closeNotificationModal()}
        >
          <div
            className="notification-card-content"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Notifications
              onClose={() => {
                closeNotificationModal();
              }}
            />
          </div>
        </div>
      )}

      <TranslateModal show={showTranslateModal} hideModal={() => updateShowTranslateModal(false)} />
    </>
  );
};

export default SideMenu;
