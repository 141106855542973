import PropTypes from 'prop-types';
import { createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useDebounce } from '../../../../hooks/useDebounce';
import { useRequest } from '../../../../hooks/useRequest';
import { WorkspaceService as Service } from '../service';

export const CampaignContext = createContext({});

export const CampaignProvider = ({ children }) => {
  const [searchByText, setSearchByText] = useState('');
  const [tabs, setTabs] = useState('influencers');

  const debouncedSearch = useDebounce(searchByText, 1000);

  const handleTabChange = (event) => {
    setTabs(event.target.dataset.tab);
  };

  const handleSearchByText = (event) => {
    setSearchByText(event.target.value);
  };

  const params = useParams();
  const campaignId = params.campaignId;

  const { data: campaign, setData: setCampaign, isLoading } = useRequest(
    () => Service.campaigns.getById(campaignId),
    { deps: [campaignId] },
  );

  // const campaignFilteredProfiles = {
  //   ...campaign,
  //   columns: campaign?.columns.map((column) => ({
  //     ...column,
  //     profiles: column.profiles.filter(
  //       (profile) =>
  //         profile.data.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
  //         profile.data.username.toLowerCase().includes(debouncedSearch.toLowerCase()),
  //     ),
  //   })),
  // };

  return (
    <CampaignContext.Provider
      value={{
        tabs,
        handleTabChange,
        campaign,
        setCampaign,
        campaignId,
        searchByText,
        handleSearchByText,
        debouncedSearch,
        isLoading
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};

export const useCampaign = () => {
  const context = useContext(CampaignContext);

  if (!context) {
    throw new Error('useCampaign must be used within a CampaignProvider');
  }

  return context;
};

CampaignProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
