import { Avatar, Form, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectCategories } from '../../../app/slice';
import {
  clearPostReclassificationStatus,
  selectInfluencerDetailsSlice,
  sendPostReclassification,
} from '../../../features/private/InfluencerCard/slice';
import { buildImgUrl } from '../../../utils';
import { humanize } from '../../../utils/formatters';
import StateStatus from '../../../utils/status';
import Button from '../Button';
import feedback from '../Feedback';
import Modal from '../Modal';
import Select from '../Select';

import './index.scss';

const { useForm } = Form;

const CardPost = ({ post, name, avatar, network, type }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = useForm();
  const appSlice = useSelector((state) => state.app);
  const categoriesList = useSelector(selectCategories);
  const influencerSlice = useSelector(selectInfluencerDetailsSlice);
  const [categories, updateCategories] = useState([]);
  const [selectedCategory, updateSelectedCategory] = useState();
  const [expandedDescription, updateExpandedDescription] = useState(false);
  const [openReclassificationModal, updateOpenReclassificationModal] = useState(false);

  const status = influencerSlice.status.sendPostReclassification;
  const user = appSlice.user;
  const canReclassify = user.features.includes('post_reclassification');

  const igPostValues = [
    { icon: 'outline-like-heart', tooltip: t('Curtidas'), value: humanize(post.likes) },
    { icon: 'outline-comment', tooltip: t('Comentários'), value: humanize(post.comments) },
    {
      icon: 'engagement',
      tooltip: t('Engajamento'),
      value: `${post.engagementRate}%`.replace('.', ','),
    },
    { icon: 'clock-outline', tooltip: t('Data da postagem'), value: post.date },
  ];
  const ttPostValues = [
    { icon: 'like-heart', tooltip: t('Curtidas'), value: humanize(post.likes) },
    { icon: 'comment', tooltip: t('Comentários'), value: humanize(post.comments) },
    { icon: 'impression', tooltip: t('Visualizações'), value: humanize(post.views) },
    {
      icon: 'engagement',
      tooltip: t('Engajamento'),
      value: `${post.engagementRate}%`.replace('.', ','),
    },
    { icon: 'clock-outline', tooltip: t('Data da postagem'), value: post.date },
  ];
  const fbPostValues = [
    { icon: 'outline-like-heart', tooltip: t('Curtidas'), value: humanize(post.likes) },
    { icon: 'outline-comment', tooltip: t('Comentários'), value: humanize(post.comments) },
    { icon: 'outline-impression', tooltip: t('Visualizações'), value: humanize(post.views) },
    {
      icon: 'engagement',
      tooltip: t('Engajamento'),
      value: `${post.engagementRate}%`.replace('.', ','),
    },
    { icon: 'clock-outline', tooltip: t('Data da postagem'), value: post.date },
  ];
  const twPostValues = [
    { icon: 'outline-like-heart', tooltip: t('Curtidas'), value: humanize(post.likes) },
    { icon: 'outline-comment', tooltip: t('Comentários'), value: humanize(post.comments) },
    { icon: 'outline-impressions', tooltip: t('Visualizações'), value: humanize(post.views) },
    {
      icon: 'engagement',
      tooltip: t('Engajamento'),
      value: `${post.engagementRate}%`.replace('.', ','),
    },
    { icon: 'clock-outline', tooltip: t('Data da postagem'), value: post.date },
  ];
  const tcPostValues = [
    { icon: 'outline-impressions', tooltip: t('Visualizações'), value: humanize(post.views) },
    {
      icon: 'engagement',
      tooltip: t('Engajamento'),
      value: `${post.engagementRate}%`.replace('.', ','),
    },
    { icon: 'clock-outline', tooltip: t('Data da postagem'), value: post.date },
  ];
  const ytPostValues = [
    { icon: 'outline-like-heart', tooltip: t('Curtidas'), value: humanize(post.likes) },
    { icon: 'outline-comment', tooltip: t('Comentários'), value: humanize(post.comments) },
    { icon: 'outline-impressions', tooltip: t('Visualizações'), value: humanize(post.views) },
    {
      icon: 'engagement',
      tooltip: t('Engajamento'),
      value: `${post.engagementRate}%`.replace('.', ','),
    },
    { icon: 'clock-outline', tooltip: t('Data da postagem'), value: post.date },
  ];

  const buildPost = (network) => {
    if (network === 'tiktok') return buildTiktokPost();
    if (network === 'youtube') return buildYoutubePost();
    if (network === 'instagram') return buildInstagramPost();
    if (network === 'facebook') return buildFacebookPost();
    if (network === 'twitter') return buildTwitterPost();
    if (network === 'twitch') return buildTwitchPost();
  };

  const includeBtnReclassification = () => {
    if (canReclassify) {
      return (
        <button
          className="btn-reclassification hover"
          onClick={() => {
            updateOpenReclassificationModal(true);
          }}
        >
          {t('Reclassificar')}
        </button>
      );
    }
  };

  const buildTiktokPost = () => {
    return (
      <div
        className="tiktok-post background-color-tiktok"
        onClick={() => updateExpandedDescription(false)}
        style={{
          backgroundImage: `url(${buildImgUrl(network, post).replace(
            'tiktok.com/',
            'www.tiktok.com/',
          )})`,
        }}
      >
        <div className="opacity-image" />

        <i className="icon icon-clip hover" onClick={() => window.open(post.link)} />

        {includeBtnReclassification()}

        <Tooltip title={expandedDescription ? '' : post.description}>
          <div
            role="button"
            onClick={(e) => {
              if (post.description !== post.descriptionSlice) {
                e.stopPropagation();

                updateExpandedDescription(!expandedDescription);
              }
            }}
            className={`post-description ${expandedDescription ? 'expanded' : ''}`}
          >
            {expandedDescription ? post.description : post.descriptionSlice}
          </div>
        </Tooltip>

        <div className="post-interactions">
          {ttPostValues.map((item, i) => (
            <Tooltip title={item.tooltip} key={item.icon}>
              <p className="interaction-data" key={i}>
                <i className={`icon icon-${item.icon}`} />
                <span className="interaction-value">{item.value}</span>
              </p>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  const buildYoutubePost = () => {
    return (
      <div className="youtube-post">
        <div
          className="thumb background-color-youtube"
          style={{ backgroundImage: `url(${post.image})` }}
        />

        <i className="icon icon-clip hover" onClick={() => window.open(post.link)} />

        {includeBtnReclassification()}

        <div className="post-interactions">
          {ytPostValues.map((item, i) => (
            <Tooltip title={item.tooltip} key={item.icon}>
              <p className="interaction-data" key={i}>
                <i className={`icon icon-${item.icon}`} />
                <span className="interaction-value">{item.value}</span>
              </p>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  const buildInstagramPost = () => {
    // const hasVideo = !!post.image;

    return (
      <div className="instagram-post">
        <div className="tooltip">
          <Tooltip title={post.description} overlayInnerStyle={{ maxHeight: '139px' }}>
            <p className="description">{post.descriptionSlice}</p>
          </Tooltip>
        </div>

        <div
          className="thumb background-color-instagram"
          style={{
            backgroundImage: `url(${post.image})`,
          }}
        >
          {post.type && <div className="type-post">{post.type}</div>}
          {includeBtnReclassification()}
        </div>

        <i className="icon icon-clip hover" onClick={() => window.open(post.link)} />

        <div className="post-interactions">
          {igPostValues.map((item, i) => (
            <Tooltip title={item.tooltip} key={item.icon}>
              <p className="interaction-data" key={i}>
                <i className={`icon icon-${item.icon}`} />

                {item.tooltip === t('Curtidas') && !post.likes && post.comments ? (
                  <span className="interaction-value">{'Ocultas'}</span>
                ) : (
                  <span className="interaction-value">{item.value}</span>
                )}
              </p>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  const buildFacebookPost = () => {
    return (
      <div className="facebook-post">
        <Tooltip title={post.description}>
          <p className="description">{post.descriptionSlice}</p>
        </Tooltip>

        <div
          className="thumb background-color-facebook"
          style={{ backgroundImage: `url(${buildImgUrl(network, post)})` }}
        />

        <i className="icon icon-clip hover" onClick={() => window.open(post.link)} />

        {includeBtnReclassification()}

        <div className="post-interactions">
          {fbPostValues.map((item, i) => (
            <Tooltip title={item.tooltip} key={item.icon}>
              <p className="interaction-data" key={i}>
                <i className={`icon icon-${item.icon}`} />
                <span className="interaction-value">{item.value}</span>
              </p>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  const buildTwitterPost = () => {
    return (
      <div className="twitter-post">
        {type && <div className="post-type">{type}</div>}

        <div className="junction-post">
          <div className="post-header">
            <Avatar src={avatar} />

            <span className="name">{name}</span>

            <i className="icon icon-clip hover" onClick={() => window.open(post.link)} />

            {includeBtnReclassification()}
          </div>

          <Tooltip title={post.description}>
            <p className="description">{post.descriptionSlice}</p>
          </Tooltip>
        </div>

        <div className="post-interactions">
          {twPostValues.map((item, i) => (
            <Tooltip title={item.tooltip} key={item.icon}>
              <p className="interaction-data" key={i}>
                <i className={`icon icon-${item.icon}`} />
                <span className="interaction-value">{item.value}</span>
              </p>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  const buildTwitchPost = () => {
    return (
      <div className="twitch-post">
        <Tooltip title={post.description}>
          <p className="title">{post.descriptionSlice}</p>
        </Tooltip>

        <div
          className="thumb background-color-twitch"
          style={{ backgroundImage: `url(${post.image})` }}
        />

        <i className="icon icon-clip hover" onClick={() => window.open(post.link)} />

        {includeBtnReclassification()}

        <div className="post-interactions">
          {tcPostValues.map((item, i) => (
            <Tooltip title={item.tooltip} key={item.icon}>
              <p className="interaction-data" key={i}>
                <i className={`icon icon-${item.icon}`} />
                <span className="interaction-value">{item.value}</span>
              </p>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };

  const onFinish = ({ category }) => {
    dispatch(
      sendPostReclassification({
        category_id: category,
        post_id: post.id,
      }),
    );
  };

  useEffect(() => {
    let list = [];

    categoriesList.list.map((category) => {
      list.push({
        value: category.key,
        key: category.name,
        disabled: true,
        icon: <i style={{ color: category.color }} className={`icon icon-${category.icon}`} />,
      });

      {
        category['subCategories'] &&
          category['subCategories'].map((subCategory) => {
            list.push({
              value: subCategory.key,
              key: subCategory.name,
            });
          });
      }
    });

    updateCategories(list);
  }, [categoriesList]);

  useEffect(() => {
    if (status === StateStatus.succeeded) {
      feedback.success(t('Publicação enviada com sucesso'));

      updateOpenReclassificationModal(false);
      dispatch(clearPostReclassificationStatus());
    } else if (status === StateStatus.failed) {
      feedback.error(t('Erro ao enviar publicação'));

      dispatch(clearPostReclassificationStatus());
    }
  }, [status]);

  return (
    <>
      <Modal
        className="reclassification-modal"
        isVisible={openReclassificationModal}
        onClose={() => updateOpenReclassificationModal(false)}
        title={t('Selecione a subcategoria a qual pertence esta publicação')}
      >
        {buildPost(network)}

        <div className="part-right">
          <div className="post-about">
            <p>
              <b>{t('Descrição')}:</b>
              <br />
              {post.description}
            </p>
          </div>

          <div>
            <b>{t('Reclassificação')}:</b>

            <Form form={form} onFinish={onFinish}>
              <Form.Item
                name="category"
                rules={[
                  {
                    required: true,
                    message: <span className="normal-12">{t('Selecione uma subcategoria')}</span>,
                  },
                ]}
              >
                <Select
                  allowClear={true}
                  value={selectedCategory}
                  placeholder={t('Selecione uma subcategoria')}
                  onChange={(value) => updateSelectedCategory(value)}
                  options={categories}
                />
              </Form.Item>

              <Button isLoading={status === StateStatus.loading} onClick={() => form.submit()}>
                {t('Enviar para reclassificação')}
              </Button>
            </Form>
          </div>
        </div>
      </Modal>

      {buildPost(network)}
    </>
  );
};

CardPost.propTypes = {
  post: PropTypes.object.isRequired,
  network: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};

export default CardPost;
