import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// 
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
// 
import { buildValue } from "../../../../utils/formatters";
import "../Charts.scss";

const BarChart = ({ data, className, height, nameFormat, isPercentage, showLegend = true }) => {
    const [series, setSeries] = useState([]);

    useEffect(() => {
        const newSeries = [];

        if(data) {
            data.forEach(item => {
                const value = typeof item.value === "string" ? parseFloat(item.value) : item.value
                
                if(showLegend)
                    newSeries.push({ 
                        name: item.label, 
                        data: [value], 
                        color: item.color || "#f70b53",
                        selected: true,
                    })
                else
                    newSeries.push({ 
                        name: item.label, 
                        data: [value], 
                        color: item.color || "#f70b53" 
                    })
            })
        }

        setSeries(newSeries);
    }, [data])

    const options = {
        chart: {
            type: "column",
            height: height,
            className: className,
            styles: {
                width: "100%"
            }
        },
        title: {
          text: ""
        },
        yAxis: {
          title: ""
        },
        xAxis: {
          visible: false
        },
        legend: {
            x: 19,
            itemDistance: 32,
            margin: 14,
            padding: 16,
            useHTML: true,
            symbolRadius: 0,
            borderRadius: 6,
            enabled: showLegend,
            backgroundColor: "#FAFAFA"
        },
        plotOptions: {
            column: {
              borderRadius: 8,
              borderRadiusTopLeft: 50,
              borderRadiusTopRight: 50
            },
            series: {
                showCheckbox: false,
                dataLabels: {
                    enabled: false,
                },
                events: {
                    checkboxClick: function () {
                        if(this.visible) {
                            this.hide();
                        } else {
                            this.show();
                        }
                    },
                    legendItemClick: function () {
                        if (this.selected) {
                          this.selected = false;
                        } else {
                          this.selected = true;
                        }
                    
                        if(this.checkbox) {
                            if (this.visible) {
                              this.checkbox.checked = false;
                            } else {
                              this.checkbox.checked = true;
                            }
                        }
                    }
                }
            }
        },
    
        tooltip: {
            formatter: function () {
                const value = `${buildValue(nameFormat, this.series.userOptions.data[this.series.userOptions.data.length - 1])}${isPercentage ? "%" : ""}` 

                return `<div class="high-tooltip">
                    ${this.series.userOptions.name}: <b>${value}</b>
                </div>` 
            }
        },

        series: series,
    };

    return series && <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}

BarChart.defaultProps = {
    nameFormat: "number"    
}

BarChart.propTypes = {
    data: PropTypes.any,
    nameFormat: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    showLegend: PropTypes.bool,
    isPercentage: PropTypes.bool,
}

export default BarChart;