import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

import AvatarNetwork from '../../../../common/components/AvatarNetwork';
import feedback from '../../../../common/components/Feedback';
import { LevelGauge } from '../../../../common/components/LevelGauge';
import { PARAMS } from '../../../../constants/params';
import { useMutation } from '../../../../hooks/useMutation';
import { buildValue, humanize } from '../../../../utils/formatters';
import { useCampaign } from '../contexts/CampaignProvider';
import { useModal } from '../contexts/ModalProvider';
import { WorkspaceService } from '../service';
import { ProfileModal } from './ProfileModal';
import Connect from './Connect';
import { Trash2 } from 'lucide-react';


export const CardList = ({ cards, columnUid }) => {
  const { openModal } = useModal();
  const { campaignId, setCampaign, searchByText } = useCampaign();
  
  // funcao abaixo é responsavel por filtrar os cards que estão no state do contexto facilitando a busca por texto
  const localCards = searchByText
    ? cards.filter((card) => {
        const name = card.data.name.toLowerCase();
        const username = card.data.username.toLowerCase();

        return (
          name.includes(searchByText.toLowerCase()) || username.includes(searchByText.toLowerCase())
        );
      })
    : cards;

  const mutation = useMutation(WorkspaceService.profiles.delete);

  const handleOpenProfileModal = (profileData) => {
    openModal(<ProfileModal data={profileData} columnUid={columnUid} />);
  };

  const handleDeleteProfile = async (profileId) => {
    try {
      await mutation.mutateAsync(campaignId, profileId);

      setCampaign((prev) => ({
        ...prev,
        columns: prev.columns.map((column) => {
          if (column.uid === columnUid) {
            return {
              ...column,
              cards: column.cards.filter((card) => card.metrics_profile_id !== profileId),
            };
          }

          return column;
        }),
      }));

      feedback.success('Perfil excluído com sucesso.');
    } catch {
      feedback.error('Falha ao excluir perfil.');
    }
  };

  return localCards.map((card, index) => (
    <Draggable key={card.uid} draggableId={card.metrics_profile_id} index={index}>
      {(provided) => (
        <div
          className="card_rm"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...provided.draggableProps.style }}
        >
          <header className="header__card_rm">
            <AvatarNetwork
              size={40}
              networkSize={16}
              src={card.data.picture}
              network={card.data.network}
            />

            <div>
              <div>
                <b>{card.data.name}</b>
                <button onClick={() => handleDeleteProfile(card.metrics_profile_id)}>
                  <Trash2 size={16} />
                </button>
              </div>
              <span>@{card.data.username}</span>
            </div>
          </header>

          <ul>
            <li>
              <span>Airscore</span>
              <b>
                {Math.floor(card.data.score * 1000)}{' '}
                <LevelGauge
                  range={PARAMS.LEVEL_GAUGE.AIR_SCORE}
                  value={Math.floor(card.data.score * 1000)}
                />
              </b>
            </li>
            <li>
              <span>Seguidores</span>
              <b>{humanize(card.data.counters?.followers)}</b>
            </li>
            <li>
              <span>Engajamento</span>
              <b>{buildValue('percent', card.data.engagement_rate / 100)}</b>
            </li>
          </ul>

          <footer className="footer__card_rm">
            <Connect
              isConnected={card.data.connect_status === 'connected'}
              network={card.data.network}
            />

            <button onClick={() => handleOpenProfileModal(card)}>Ver mais</button>
          </footer>
        </div>
      )}
    </Draggable>
  ));
};

CardList.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      order: PropTypes.number.isRequired,
      air_id: PropTypes.string.isRequired,
      posts_count: PropTypes.number.isRequired,
      videos_count: PropTypes.number.isRequired,
      network: PropTypes.string.isRequired,
      uid: PropTypes.string.isRequired,
      metrics_profile_id: PropTypes.string.isRequired,
      stories_count: PropTypes.number.isRequired,
      status: PropTypes.oneOf(['pending', 'approved', 'rejected']).isRequired,
      data: PropTypes.object.isRequired,
    }),
  ).isRequired,
  columnUid: PropTypes.string.isRequired,
};
