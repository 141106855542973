import { Header } from './components/Header';
import { Modal } from './components/Modal';
import { Table } from './components/Table';
import { ModalProvider } from './contexts/ModalProvider';
import { WorkspacesProvider } from './contexts/WorkspacesProvider';

import './styles.scss';

export const Workspaces = () => {
  return (
    <WorkspacesProvider>
      <ModalProvider>
        <div className="background-page workspaces">
          <div className="container__workspaces">
            <Header />

            <main>
              <Table />
            </main>
          </div>
        </div>

        <Modal />
      </ModalProvider>
    </WorkspacesProvider>
  );
};
