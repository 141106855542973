import { ExternalLink, CircleCheck } from 'lucide-react';
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import feedback from '../../../../../common/components/Feedback';

const Connect = ({ isConnected, network }) => {
  
  const copyHanlder = () => {
    navigator.clipboard.writeText("https://airkit.to/").then(() => {
      feedback.success('Link copiado para a área de transferência');
    }).catch(() => {
      feedback.error('Falha ao copiar o link, verifique suas permissões de navegador.');
    })
  }
  
  return ['instagram'].includes(network) ? (
    <div>
      {isConnected ? (
        <div className="conect_card_rm">
          <CircleCheck size={18} absoluteStrokeWidth />
          <p>Perfil conectado</p>
        </div>
      ) : (
        <div onClick={copyHanlder} className="conect_card_rm">
          <p>Solicitar conexão</p>
          <ExternalLink size={18} absoluteStrokeWidth />
        </div>
      )}
    </div>
  ) : (
    '-'
  );
};

Connect.propTypes = {
  isConnected: PropTypes.bool,
  network: PropTypes.string,
};

export default Connect;
