import PropTypes from 'prop-types';
import { forwardRef, memo } from 'react';

import { buildValue, humanize } from '../../../../../utils/formatters';
import AvatarNetwork from '../../../../../common/components/AvatarNetwork';

const ProfileBase = forwardRef(({ profile, ref, onSelectProfile }) => {
  return (
    <li
      key={profile.username}
      className="profile__add_influencer_modal__rm"
      onClick={() => onSelectProfile(profile)}
      ref={ref}
    >
      <div className="header__add_influencer_modal__rm">
         <AvatarNetwork
            size={40}
            networkSize={16}
            network={profile.network}
            src={profile.picture}
          />
        <div>
          <b>{profile.name}</b>
          <span>{profile.username}</span>
        </div>
      </div>

      <footer>
        <span>{humanize(profile.counters.followers)} seguidores</span>
        <span>•</span>
        <span>{buildValue('percent', profile.engagement_rate / 100)} engajamento</span>
      </footer>
    </li>
  );
});

ProfileBase.displayName = 'Profile';

export const Profile = memo(ProfileBase);

ProfileBase.propTypes = {
  profile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    picture: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    network: PropTypes.string.isRequired,
    counters: PropTypes.shape({
      followers: PropTypes.number.isRequired,
    }),
    engagement_rate: PropTypes.number.isRequired,
  }),
  onSelectProfile: PropTypes.func.isRequired,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLLIElement) }),
  ]),
};
