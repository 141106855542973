import { z } from 'zod';
import { formatCurrency } from '../../../../../utils/formatters';

const baseVideoSchema = z.object({
  videos: z.object({
    quantity: z.number().min(0, 'Quantidade deve ser ao menos 0'),
    investment: z.string().transform((value) => Number(value.replace(/\D/g, '')) / 100),
  }),
  is_fee: z.boolean().default(false),
  is_barter: z.boolean().default(false),
});

const instagramSchema = z.object({
  network: z.literal('instagram'),
  posts: z.object({
    quantity: z.number().min(0, 'Quantidade deve ser ao menos 0'),
    investment: z.string().transform((value) => Number(value.replace(/\D/g, '')) / 100),
  }),
  reels: z.object({
    quantity: z.number().min(0, 'Quantidade deve ser ao menos 0'),
    investment: z.string().transform((value) => Number(value.replace(/\D/g, '')) / 100),
  }),
  stories: z.object({
    quantity: z.number().min(0, 'Quantidade deve ser ao menos 0'),
    investment: z.string().transform((value) => Number(value.replace(/\D/g, '')) / 100),
  }),
  is_fee: z.boolean().default(false),
  is_barter: z.boolean().default(false),
});

// Schema final com discriminação de redes
export const negotiationSchema = z.discriminatedUnion('network', [
  baseVideoSchema.extend({ network: z.literal('youtube') }),
  baseVideoSchema.extend({ network: z.literal('tiktok') }),
  baseVideoSchema.extend({ network: z.literal('twitch') }),
  instagramSchema,
]);

export const defaultValues = (profile) => {
  const fields = {
    instagram: {
      reels: {
        quantity: profile.reels_count ?? 0,
        investment: formatCurrency(profile.reels_price),
      },
      stories: {
        quantity: profile.stories_count,
        investment: formatCurrency(profile.stories_price),
      },
      posts: {
        quantity: profile.posts_count,
        investment: formatCurrency(profile.posts_price),
      },
      is_fee: profile.is_fee,
      is_barter: profile.is_barter,
    },
    tiktok: {
      videos: {
        quantity: profile.videos_count,
        investment: formatCurrency(profile.videos_price),
      },
      is_fee: profile.is_fee,
      is_barter: profile.is_barter,
    },
    twitch: {
      videos: {
        quantity: profile.videos_count,
        investment: formatCurrency(profile.videos_price),
      },
      is_fee: profile.is_fee,
      is_barter: profile.is_barter,
    },
    youtube: {
      videos: {
        quantity: profile.videos_count,
        investment: formatCurrency(profile.videos_price),
      },
      is_fee: profile.is_fee,
      is_barter: profile.is_barter,
    },
  };

  return fields[profile.network];
};
