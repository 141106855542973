import { ExternalLink, Mail, MapPin, Phone } from 'lucide-react';
import PropTypes from 'prop-types';
import AvatarNetwork from '../../../../../common/components/AvatarNetwork';
import { formatList } from '../../../../../utils/formatters';

const bioDataIcon = {
  emails: <Mail size={20} />,
  phones: <Phone size={20} />,
  places: <MapPin size={20} />,
};

export const Header = ({ profile }) => {
  const bioDataMapper = {
    places: profile.data.biography_data.places.length ? profile.data.biography_data.places : null,
    phones: profile.data.biography_data.phones.length ? profile.data.biography_data.phones : null,
    emails: profile.data.biography_data.emails.length ? profile.data.biography_data.emails : null,
  };
  const bioData = Object.entries(bioDataMapper);
  const hasBio = bioData.some(({ 1: value }) => value !== null);

  return (
    <header className="header__profile_modal">
      <AvatarNetwork
        size={96}
        networkSize={32}
        src={profile.data.picture}
        network={profile.data.network}
      />

      <div className="info__header__profile_modal">
        <div>
          <strong>{profile.data.name}</strong>
          <span style={{cursor: 'pointer'}} onClick={() => window.open(profile.data.permalink, "_blank")}>
            @{profile.data.username} <ExternalLink size={16} />
          </span>
        </div>

        {hasBio && (
          <ul>
            {bioData.map(
              ([key, value]) =>
                value && (
                  <li key={key}>
                    {bioDataIcon[key]} {formatList(value)}
                  </li>
                ),
            )}
          </ul>
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  profile: PropTypes.shape({
    order: PropTypes.number.isRequired,
    air_id: PropTypes.string.isRequired,
    posts_count: PropTypes.number.isRequired,
    videos_count: PropTypes.number.isRequired,
    network: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    metrics_profile_id: PropTypes.string.isRequired,
    stories_count: PropTypes.number.isRequired,
    status: PropTypes.oneOf(['pending', 'approved', 'rejected']).isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
};
