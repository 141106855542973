import PropTypes from 'prop-types';
import { useState } from 'react';

import Modal from '../../../../../common/components/Modal';
import { useModal } from '../../contexts/ModalProvider';
import { Header } from './Header';
import { Negotiation } from './Negotiation';
import { Performance } from './Performance';
import Notes from './Notes';
import './styles.scss';

export const ProfileModal = ({ data, columnUid }) => {
  const [tabs, setTabs] = useState('negotiation');

  const { isOpen, closeModal } = useModal();

  const handleTabChange = (event) => {
    setTabs(event.target.dataset.tab);
  };

  return (
    <Modal isVisible={isOpen} onClose={closeModal} className="profile_modal">
      <div className="content__profile_modal">
        <Header profile={data} />
        <ul className="tabs__profile_modal">
          <li>
            <button
              data-active={tabs === 'negotiation'}
              data-tab="negotiation"
              onClick={handleTabChange}
            >
              Negociação
            </button>
          </li>
          <li>
            <button
              type="button"
              data-active={tabs === 'notes'}
              data-tab="notes"
              onClick={handleTabChange}
            >
              Notas sobre o Influenciador
            </button>
          </li>
          <li>
            <button
              type="button"
              data-active={tabs === 'performance'}
              data-tab="performance"
              onClick={handleTabChange}
            >
              Dados de desempenho
            </button>
          </li>
        </ul>

        {tabs === 'negotiation' && <Negotiation profile={data} columnUid={columnUid} />}
        {tabs === 'performance' && <Performance profile={data} />}
        {tabs === 'notes' && (
          <div style={{marginTop: 20}} className='comments-outside'>
            <Notes profile={data} />
          </div>
        )}
      </div>
    </Modal>
  );
};

ProfileModal.propTypes = {
  data: PropTypes.shape({
    order: PropTypes.number.isRequired,
    air_id: PropTypes.string.isRequired,
    posts_count: PropTypes.number.isRequired,
    videos_count: PropTypes.number.isRequired,
    network: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    metrics_profile_id: PropTypes.string.isRequired,
    stories_count: PropTypes.number.isRequired,
    status: PropTypes.oneOf(['pending', 'approved', 'rejected']).isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
  columnUid: PropTypes.string.isRequired,
};
