import { zodResolver } from '@hookform/resolvers/zod';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';

import Button from '../../../../../common/components/Button';
import CheckBox from '../../../../../common/components/CheckBox/';
import feedback from '../../../../../common/components/Feedback';
import { useMutation } from '../../../../../hooks/useMutation';
import { formatCurrency } from '../../../../../utils/formatters';
import { useCampaign } from '../../contexts/CampaignProvider';
import { useModal } from '../../contexts/ModalProvider';
import { WorkspaceService } from '../../service';
import { InvestmentSection } from './InvestmentSection';
import { defaultValues, negotiationSchema } from './schema';

const fields = {
  instagram: [
    {
      name: 'posts',
      label: 'Posts',
      renderValue: (value) => `${value} Posts`,
    },
    {
      name: 'reels',
      label: 'Reels',
      renderValue: (value) => `${value} Reels`,
    },
    {
      name: 'stories',
      label: 'Stories',
      renderValue: (value) => `${value} Stories`,
    },
  ],
  youtube: [
    {
      name: 'videos',
      label: 'Vídeos',
      renderValue: (value) => `${value} Vídeos`,
    },
  ],
  tiktok: [
    {
      name: 'videos',
      label: 'Vídeos',
      renderValue: (value) => `${value} Vídeos`,
    },
  ],
  twitch: [
    {
      name: 'videos',
      label: 'Vídeos',
      renderValue: (value) => `${value} Vídeos`,
    },
  ],
};

export const Negotiation = ({ profile, columnUid }) => {
  const { campaignId, setCampaign } = useCampaign();
  const { closeModal } = useModal();

  const form = useForm({
    resolver: zodResolver(negotiationSchema),
    defaultValues: {
      network: profile.network,
      ...defaultValues(profile),
    },
  });

  const mutation = useMutation(WorkspaceService.profiles.update);

  const handleClearAll = () => {
    const clearedValues = fields[profile.network].reduce((acc, field) => {
      acc[field.name] = { quantity: 0, investment: formatCurrency(0) };

      return acc;
    }, {});

    form.reset({ ...clearedValues, is_fee: false, is_barter: false });
  };

  const handleSubmit = form.handleSubmit(async (formData) => {
    const isInstagram = formData.network === 'instagram';

    const contentData = isInstagram
      ? {
          reels_count: formData.reels.quantity,
          reels_price: formData.reels.investment,
          posts_count: formData.posts.quantity,
          posts_price: formData.posts.investment,
          stories_count: formData.stories.quantity,
          stories_price: formData.stories.investment,
        }
      : {
          videos_count: formData.videos.quantity,
          videos_price: formData.videos.investment,
        };

    const data = {
      profile_id: profile.metrics_profile_id,
      column_uid: columnUid,
      is_fee: formData.is_fee,
      is_barter: formData.is_barter,
      ...contentData,
    };

    try {
      await mutation.mutateAsync(campaignId, profile.metrics_profile_id, data);

      setCampaign((prev) => ({
        ...prev,
        columns: prev.columns.map((column) => {
          if (column.uid === columnUid) {
            return {
              ...column,
              cards: column.cards.map((card) => {
                if (card.metrics_profile_id === profile.metrics_profile_id) {
                  return {
                    ...card,
                    is_fee: formData.is_fee,
                    is_barter: formData.is_barter,
                    ...contentData,
                  };
                }

                return card;
              }),
            };
          }

          return column;
        }),
      }));

      feedback.success('Negociação salva com sucesso.');
      closeModal();
    } catch {
      feedback.error('Falha ao salvar negociação.');
    }
  });

  return (
    <FormProvider {...form}>
      <form className="negotiation__profile_modal">
        <InvestmentSection fields={fields[profile.network]} />
        <section>
          <b>Modelo de negociação</b>
          <div>
            <div className="input_checkbox">
              <Controller
                name="is_fee"
                control={form.control}
                render={({ field }) => (
                  <CheckBox checked={field.value} onChange={field.onChange} text="Cachê" />
                )}
              />
            </div>
            <div className="input_checkbox">
              <Controller
                name="is_barter"
                control={form.control}
                render={({ field }) => (
                  <CheckBox checked={field.value} onChange={field.onChange} text="Permuta" />
                )}
              />
            </div>
          </div>
        </section>

        <footer>
          <Button onClick={handleClearAll}>Limpar tudo</Button>
          <Button type="submit" onClick={handleSubmit}>
            Salvar negociação
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};

Negotiation.propTypes = {
  profile: PropTypes.shape({
    order: PropTypes.number.isRequired,
    air_id: PropTypes.string.isRequired,
    posts_count: PropTypes.number.isRequired,
    videos_count: PropTypes.number.isRequired,
    network: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    metrics_profile_id: PropTypes.string.isRequired,
    stories_count: PropTypes.number.isRequired,
    status: PropTypes.oneOf(['pending', 'approved', 'rejected']).isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
  columnUid: PropTypes.string.isRequired,
};
