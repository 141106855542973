import { X } from 'lucide-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import './styles.scss';

export const InputTags = ({ value, onChange }) => {
  const [tag, setTag] = useState('');
  const [tags, setTags] = useState({
    hashtags: value?.hashtags || [],
    mentions: value?.mentions || [],
  });

  useEffect(() => {
    onChange?.(tags);
  }, [tags]);

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };

  const handleAddTag = (event) => {
    if ((event.key === 'Enter' || event.key === 'Tab' || event.key === ' ') && tag.trim()) {
      event.preventDefault();
      const tagType = tag.startsWith('#') ? 'hashtags' : tag.startsWith('@') ? 'mentions' : null;

      if (tagType) {
        setTags((prev) => ({
          ...prev,
          [tagType]: [...new Set([...prev[tagType], tag])], // Evita duplicados
        }));
        setTag('');
      }
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const tagType = tagToRemove.startsWith('#') ? 'hashtags' : 'mentions';

    setTags((prev) => ({
      ...prev,
      [tagType]: prev[tagType].filter((tag) => tag !== tagToRemove),
    }));
  };

  const isInvalidTag = !(tag.startsWith('#') || tag.startsWith('@'));

  return (
    <div className="input_tags">
      <ul className="tags__input_tags">
        {tags.hashtags.map((tag) => (
          <li className="tag__input_tags" key={tag}>
            {tag}
            <button onClick={() => handleRemoveTag(tag)} aria-label={`Remover ${tag}`}>
              <X size={16} />
            </button>
          </li>
        ))}
        {tags.mentions.map((tag) => (
          <li className="tag__input_tags" key={tag}>
            {tag}
            <button onClick={() => handleRemoveTag(tag)} aria-label={`Remover ${tag}`}>
              <X size={16} />
            </button>
          </li>
        ))}
      </ul>
      <input
        type="text"
        value={tag}
        onChange={handleTagChange}
        onKeyDown={handleAddTag}
        placeholder="#airfluencers @airfluencers"
        style={{ color: isInvalidTag ? '#ff4c4c' : '#4a4a4a' }}
      />
    </div>
  );
};

InputTags.propTypes = {
  value: PropTypes.shape({
    hashtags: PropTypes.arrayOf(PropTypes.string),
    mentions: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
};
