import moment from 'moment';
import { z } from 'zod';

const DATE_FORMAT = 'DD/MM/YYYY';

const dateSchema = z
  .string()
  .min(1, { message: 'Data é obrigatória.' })
  .refine((value) => moment(value, DATE_FORMAT, true).isValid(), {
    message: `Formato de data inválido.`,
  })
  .transform((value) => moment(value, DATE_FORMAT).toISOString())
  .default('');

export const schema = z
  .object({
    title: z
      .string()
      .min(1, 'Título é obrigatório.')
      .min(3, 'Título deve ter no mínimo 3 caracteres.')
      .default(''),
    tags: z.object({
      hashtags: z.array(z.string()).nullable(),
      mentions: z.array(z.string()).nullable(),
    }),
    start_date: dateSchema,
    end_date: dateSchema,
  })
  .refine(
    (data) => {
      return moment(data.end_date).isSameOrAfter(moment(data.start_date));
    },
    {
      message: 'Data final deve ser posterior ou igual à data de início.',
      path: ['end_date'],
    },
  );
