import React from 'react';
// 
import { useTranslation } from 'react-i18next';
//
import Button from "../../../common/components/Button"
//
import star from '../../assets/icons/workspace.svg';
//
import './index.scss';
import { useNavigate } from 'react-router-dom';

const WorkspaceRecommendation = () => {
    const { t } = useTranslation();
    const navigate =  useNavigate()

    const click = () => {
        navigate("/workspaces")
    }

    return (
        <div
            role="button" 
            className="extension-recommendation hover"
            onClick={() => click()}
        >
            <img src={star} alt="computador" />

            <div className="presentation-text">
                <span className="text-1">{t("Conheça a Área de Trabalho, nova funcionalidade da Airsearch")}.</span>

                <span className="text-2">
                    {t("Gerencie todo seu processo de marketing de influência em uma única interface.")}
                </span>
            </div>

            <Button
                className="outline-dark"
            >
                {t("Começar")}
            </Button>
        </div>
    );
};

export default WorkspaceRecommendation;
