import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import Input from '../../../../../common/components/Input';
import { formatCurrency } from '../../../../../utils/formatters';
import { maskCurrency } from '../../../../../utils/masks';
import { SpinButton } from './SpinButton';

export const InvestmentSection = ({ fields }) => {
  const form = useFormContext();

  const watchedValues = form.watch();

  const totalInvestment = fields.reduce((acc, field) => {
    const fieldValue = watchedValues[field.name]?.investment || '0';
    const valueFormatted = Number(fieldValue.replace(/\D/g, '')) / 100;

    return acc + valueFormatted;
  }, 0);

  return (
    <section>
      <b>Investimento</b>

      {fields.map((item) => (
        <div key={item.name}>
          <div>
            <label>{item.label}</label>
            <Controller
              name={`${item.name}.quantity`}
              control={form.control}
              render={({ field }) => (
                <SpinButton
                  minValue={0}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  renderValue={item.renderValue}
                />
              )}
            />
          </div>
          <div>
            <label>Investimento</label>
            <Controller
              name={`${item.name}.investment`}
              control={form.control}
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={(event) => field.onChange(maskCurrency(event.target.value))}
                />
              )}
            />
          </div>
        </div>
      ))}
      <div>
        <div>
          <label>Investimento total</label>
          <Input isDisabled={true} value={formatCurrency(totalInvestment)} />
        </div>
      </div>
    </section>
  );
};

InvestmentSection.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      renderValue: PropTypes.func.isRequired,
    }).isRequired,
  ).isRequired,
};
