import InfluencerNotes from "../../../InfluencerCard/components/ModalRemarksAboutInfluencer/notes"
import PropTypes from 'prop-types';

const Notes = ({profile}) => {
    const influencer =  {   
        id: profile.data.id,
        username: profile.data.username,
        network: profile.data.network,
        profileUrl: profile.data.profile_url,
    }   
    return (
        <InfluencerNotes showHeader={false} influencer={influencer} />
    )
}

Notes.propTypes = {
    profile: PropTypes.object,
}

export default Notes;

