import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';

export const Toggle = ({ initialState, handleToggle }) => {
  const [isOn, setIsOn] = React.useState(initialState);

  return (
    <label className="toggle-switch">
      <input
        type="checkbox"
        checked={isOn}
        onChange={() => { setIsOn(!isOn); handleToggle(!isOn);  }}
        className="toggle-switch-checkbox"
      />
      <span className={`toggle-switch-slider ${isOn && 'toggle-on'}`}></span>
    </label>
  );
};

Toggle.propTypes = {
  initialState: PropTypes.bool,
  handleToggle: PropTypes.func,
};
Toggle.defaultProps = {
  initialState: false,
  handleToggle: () => {},
};
