import { Minus, Plus } from 'lucide-react';
import PropTypes from 'prop-types';

export const SpinButton = ({ value, onChange, minValue, maxValue, renderValue }) => {
  const canDecrease = minValue === undefined || value > parseInt(minValue);
  const canIncrease = maxValue === undefined || value < parseInt(maxValue);

  const handleDecrease = () => {
    if (!canDecrease) return;
    onChange(value - 1);
  };

  const handleIncrease = () => {
    if (!canIncrease) return;
    onChange(value + 1);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') handleDecrease();
    if (event.key === 'ArrowUp') handleIncrease();
  };

  const inputValue = renderValue?.(value) ?? value;

  return (
    <div className="spin_button">
      <input value={inputValue} readOnly onKeyDown={handleKeyDown} />
      <button type="button" onClick={handleDecrease} disabled={!canDecrease}>
        <Minus />
      </button>
      <button type="button" onClick={handleIncrease} disabled={!canIncrease}>
        <Plus />
      </button>
    </div>
  );
};

SpinButton.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  renderValue: PropTypes.func,
};
