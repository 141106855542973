import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { buildValue, humanize } from '../../../../../utils/formatters';
import CardPost from '../../../../../common/components/CardPost';
import { useTranslation } from 'react-i18next';
import { WorkspaceService } from '../../service';
import PostModel from '../../../../../models/PostModel';
import feedback from '../../../../../common/components/Feedback';
import DemographicsModel from '../../../../../models/DemographicsModel';
import './styles.scss';
import Loading from '../../../../../common/components/Loading';

const calcRelevance = (data, byRelevance = true) => {
  if (byRelevance) {
    return data.reduce((acc, curr) => {
      return curr.relevance > acc.relevance ? curr : acc;
    });
  }

  return data.reduce((acc, curr) => {
    return curr.value > acc.value ? curr : acc;
  });
};

export const Performance = ({ profile }) => {
  const { t } = useTranslation();

  const aboutFollowersItems = [
    { name: t('Sexo'), icon: 'user', alias: 'gender', type: 'donuts' },
    { name: t('Tem filhos?'), icon: 'group', alias: 'parental', type: 'donuts' },
    { name: t('Idade'), icon: 'baby', alias: 'census', type: 'bars' },
    { name: t('Local'), icon: 'location', alias: 'location', type: 'bars' },
    { name: t('Estado civil'), icon: 'rings', alias: 'marital', type: 'donuts' },
  ];

  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
    status: 'loading',
    posts: [],
  });

  const [demographics, setDemographics] = useState({
    status: 'loading',
    data: null,
  });

  useEffect(() => {
    WorkspaceService.posts
      .list(profile.metrics_profile_id, pagination.current, 3)
      .then((response) => {
        setPagination((prev) => ({
          ...prev,
          total: response.total_pages,
          status: 'completed',
          posts: response.data.items.map((post) => new PostModel().fromJson(post)),
        }));
      });
  }, [pagination.current]);

  useEffect(() => {
    WorkspaceService.demographics
      .get(profile.metrics_profile_id, profile.data.username, profile.data.network)
      .then((response) => {
        setDemographics({
          status: 'completed',
          data: new DemographicsModel().fromJson(response.data, {}),
        });
      })
      .catch(() => {
        feedback.error('Erro ao carregar dados demográficos, tente novamente mais tarde.');
      });
  }, []);
  
  return (
    <div className="performance__profile_modal">
      <ul className="insights__profile_modal">
        <li>
          <b>{Math.floor(profile.data.score * 1000)}</b>
          <p>Airscore</p>
        </li>
        <li>
          <b>{humanize(profile.data.counters?.followers)}</b>
          <p>Seguidores</p>
        </li>
        <li>
          <b>{buildValue('percent', profile.data.engagement_rate / 100)}</b>
          <p>Engajamento</p>
        </li>
      </ul>

      <div className="posts-container">
        <div className="title-part">Últimas postagens</div>
        <br />

        <div className="posts-scroll">
          {pagination.status === 'completed' ? (  
            <div className="posts-scroller">
              {pagination.posts && pagination.posts.length > 0 ? (
                <>
                  {pagination.posts.map((post) => {
                    return (
                      <CardPost
                        post={post}
                        key={post.id}
                        name={profile.data.name}
                        avatar={profile.data.picture}
                        network={profile.network}
                        type={t('Mídia')}
                      />
                    );
                  })}
                </>
              ) : (
                'Sem Posts'
              )}
            </div>
          ) : (
            <div className="container-loading">
              <Loading />
            </div>
          )}
        </div>
      </div>

      <div className="metrics_container__profile_modal">
        <div className="title-part">Dados demográficos</div>
        <br />
        {demographics.status === 'completed' ? (
          <div className="chart-items">
            {demographics.data &&
              aboutFollowersItems.map(({ type, name, alias, icon }, index) => {
                const chartData = demographics.data[type][alias];

                let value;
                let label;

                if (chartData && Object.keys(chartData).length > 0) {
                  const greaterRelevance = calcRelevance(chartData, alias !== 'gender');

                  value = `(${(greaterRelevance.value / 100).toFixed(2)}%)`.replaceAll('.', ',');
                  label = greaterRelevance.label;
                }

                return (
                  <div key={`chart-item-${index}`} className="chart-item">
                    <p className="value-name">{name}</p>

                    <div className="junction-value">
                      <div>
                        <i className={`icon icon-${icon}`} />

                        {label && <b>{t(label)}</b>}

                        {!value && <span className="no-data">-</span>}
                      </div>
                      {value && <span className="value">{value}</span>}
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="container-loading">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

Performance.propTypes = {
  profile: PropTypes.shape({
    order: PropTypes.number.isRequired,
    air_id: PropTypes.string.isRequired,
    posts_count: PropTypes.number.isRequired,
    videos_count: PropTypes.number.isRequired,
    network: PropTypes.string.isRequired,
    uid: PropTypes.string.isRequired,
    metrics_profile_id: PropTypes.string.isRequired,
    stories_count: PropTypes.number.isRequired,
    status: PropTypes.oneOf(['pending', 'approved', 'rejected']).isRequired,
    data: PropTypes.object.isRequired,
  }).isRequired,
};
