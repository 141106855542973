import { Modal as AntModal } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import './index.scss';
import InfluencerNotes from './notes';

const ModalRemarksAboutInfluencer = ({ onClose, isVisible, influencer }) => {
  return (
    <AntModal className="modal-comments" footer={false} open={isVisible} onCancel={onClose}>
      <InfluencerNotes influencer={influencer} />
    </AntModal>
  );
};

ModalRemarksAboutInfluencer.defaultProps = {
  isVisible: false,
};

ModalRemarksAboutInfluencer.propTypes = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  influencer: PropTypes.object,
};

export default ModalRemarksAboutInfluencer;
